<template>
  <div id="setMaint" class="bg">
        <div class="text-center">
            <WarningDlg ref="warning" />
            <br/><br/>
            <div class="cui">
              <v-chip
                  class="ma-2 text-bold text-medium"
                  outlined
                  >
                  SL1 Tenant Site / Devices Maintenance
              </v-chip>
            </div>
            <div>
             <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0" cols="3">
                  <div class="ttec_contentWrapper pa-2 ml-2">
                    <v-chip class="text-medium text-bold-ttec mt-2 mb-2">Select customer</v-chip>
                    <v-select ma-0 class="text-small"
                          v-model="selectedClient"
                          label="Select a customer"
                          single-line
                          @change="reset_variables"
                          :items="clientList"
                          density="compact"
                      ></v-select>
                    <v-chip class="text-medium text-bold-ttec mt-0 ma-0">Select site/devices maintenance</v-chip>
                    <br/><span class="text-small" v-if="selectedClient != ''">[{{ selectedClient }}]</span>
                    <section v-if="selectedClient != ''">
                      <input type="radio" v-model="choice" v-on:change="getClientDevices()" value="0"><span class="text-small ml-2">Entire site</span>
                      <input class="ml-6" type="radio" v-model="choice" v-on:change="entireSite = false; showClientDevices()" value="1"><span class="text-small ml-2">Device(s)</span>
                      <br />
                    </section>
                    <br/><br/>
                      <v-text-field 
                        class="mr-3"
                        v-model="snow_ticket"
                        hide-details="auto"
                        label="ServiceNow Ticket (*)"
                        density="compact"
                      ></v-text-field>
                      <v-text-field 
                        class="mr-3 mt-2"
                        v-model="email"
                        hide-details="auto"
                        label="Email (*)"
                        density="compact"
                      ></v-text-field>
                      <v-chip class="text-xsmall text-bold-ttec mt-6">Select maintenance duration </v-chip>
                      <br/><br/>
                      <span class="ttec_left text-bold-ttec text-xsmall">Start:</span> <span v-if="maint_absstart" class="ttec_right text-xsmall"> (UTC: {{ maint_absstart_utc  }}) *</span>
                      <VueDatePicker v-model="maint_absstart" ></VueDatePicker>
                      <span class="ttec_left text-bold-ttec text-xsmall">End:</span> <span v-if="maint_absend" class="ttec_right text-xsmall"> (UTC: {{ maint_absend_utc  }}) *</span>
                      <VueDatePicker  v-model="maint_absend" ></VueDatePicker>
                      <v-spacer></v-spacer>
                        <v-btn :disabled="snow_ticket === '' || email === '' || maint_absstart_utc === '' || maint_absend_utc === '' || maint_absstart >= maint_absend  || selectedDid.length == 0" rounded class="text-bold-ttec primary" @click.stop="setMaint()">Schedule Maintenance</v-btn>
                      <v-spacer></v-spacer>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                  </div>
               </v-col>
                <v-col class="ma-0 pa-0" cols="9">
                  <div v-if="selectedClient != '' && entireSite == false" class="ttec_contentWrapper pa-2 ml-0 mr-0">
                    <v-chip class="text-medium text-bold-ttec mt-2">Customer Device List</v-chip>
                    <br/><br/>
                    <v-text-field
                        class="mr-8 ml-1 mb-0"
                        v-model="search"
                        append-icon="mdi-magnify"
                        density="compact"
                        single-line
                        placeholder="Search by Device, IP, Device Class, Parent Device or Root Device"
                    ></v-text-field>
                    <v-data-table
                      :headers="header_devices"
                      :items="deviceEntries"
                      :items-per-page="-1"
                      item-value="did"
                      v-model="selectedDid"
                      class="elevation-6"
                      :search="search"
                      :loading="loadDevicesList"
                      loading-text="Loading ..."
                      show-select
                      density="compact"
                      >
                    </v-data-table>
                  </div>
               </v-col>
             </v-row>
            </div>
            <v-snackbar
                        v-model="hasSaved"
                        :timeout="3000"
                        attach
                        position="absolute"
                        location="bottom left"
                        >
                        {{ this.snackbar_msg }}
            </v-snackbar>
        </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import WarningDlg from './WarningDlg'
import { useToast } from 'vue-toastification'

export default {
  name: 'SetMaintenance',
  components: {
    VueDatePicker,
    WarningDlg
  },
  data: () => ({
    selectedClient: '',
    entireSite: true,
    deviceEntries: [],
    header_devices: [],
    search: '',
    clientList: [],
    isHidden: true,
    showStatus: false,
    showTestStatus: false,
    snow_ticket: '',
    email: '',
    selectedDid: [],
    loadDevicesList: false,
    maint_absstart: null,
    maint_start: 0,
    maint_absend: null,
    maint_end: 0,
    maint_absstart_utc: '',
    maint_absend_utc: '',
    snackbar_msg: 'Record has been updated',
    hasSaved: false,
    choice: -1
  }),

  created () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      axios.post(`${API_URL}/api/sl1/sl1_clients_pull`, this.queryData, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          var returnObj = payload.data
          this.clientList = returnObj.clientList
        })
    })
  },

  watch: {
    maint_absstart: function (val) {
      var myDate = Date.parse(val)
      var curData = new Date()
      if (myDate < curData) {
        this.maint_absstart_utc = ''
        this.$refs.warning.open(
          'Warning',
          'Maintenance start time must be at least 2 minutes later than current time. Please correct start time and try again.'
        )
      } else {
        this.maint_start = myDate / 1000
        var momentStartDate = moment(val, 'MM/DD/YYYY HH:mm:ss')
        // ( UTC: 2023-03-15T16:39:00.000Z)
        var startDateLbl = momentStartDate.toISOString()
        var tokens = startDateLbl.split('T')
        var timeTokens = tokens[1].split(':')
        var dateTokens = tokens[0].split('-')
        this.maint_absstart_utc = timeTokens[0] + ':' + timeTokens[1] + ':' + dateTokens[1] + '/' + dateTokens[2] + '/' + dateTokens[0].slice(2, 4)
      }
    },
    maint_absend: function (val) {
      var myEndDate = Date.parse(val)
      this.maint_end = myEndDate / 1000
      var momentEndDate = moment(val, 'MM/DD/YYYY HH:mm:ss')
      var startDateLbl = momentEndDate.toISOString()
      var tokens = startDateLbl.split('T')
      var timeTokens = tokens[1].split(':')
      var dateTokens = tokens[0].split('-')
      this.maint_absend_utc = timeTokens[0] + ':' + timeTokens[1] + ':' + dateTokens[1] + '/' + dateTokens[2] + '/' + dateTokens[0].slice(2, 4)
    },
    selectedClient: function (val) {
      this.deviceEntries = []
      this.header_devices = []
      this.choice = -1
      this.entireSite = true
      this.snow_ticket = ''
      this.email = ''
      this.maint_start = 0
      this.maint_end = 0
      this.maint_absstart_utc = ''
      this.maint_absend_utc = ''
    }
  },

  methods: {
    reset_variables () {
      this.deviceEntries = []
      this.header_devices = []
      this.choice = -1
      this.entireSite = true
      this.snow_ticket = ''
      this.email = ''
      this.maint_start = 0
      this.maint_end = 0
      this.maint_absstart_utc = ''
      this.maint_absend_utc = ''
    },
    getLoginUser () {
      return this.$store.getters.getLoginUser
    },
    setMaint () {
      fetch('/config.json').then(res => res.json()).then(config => {
        let API_URL = config['API_URL']
        var queryData = {
          'did_list': this.selectedDid,
          'start': this.maint_start,
          'end': this.maint_end,
          'ticket': this.snow_ticket,
          'email': this.email,
          'user': this.getLoginUser()
        }
        axios.post(`${API_URL}/api/sl1/add_maintenace`, queryData, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
          (payload) => {
            var retObj = payload.data
            var status = retObj.status
            if (status === 'ok') {
              const toast = useToast()
              toast.success('Record had been updated.')
            }
            
          })
      })
    },
    getClientDevices () {
      this.entireSite = true
      this.selectedDid = []  // reset to empty list
      var API_URL = 'http://xx.xx.xx.xx:xxxx'
      fetch('/config.json').then(res => res.json()).then(config => {
        API_URL = config['API_URL']
        var queryData = { 'client': this.selectedClient }
        axios.post(`${API_URL}/api/sl1/devices_pull`, queryData, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
          (payload) => {
            this.loadDevicesList = false
            var retObj = payload.data
            var devList = retObj.devices
            for (let i = 0; i < devList.length; i++) {
              this.selectedDid.push(devList[i].did)
            }
          })
      })
    },
    showClientDevices () {
      if (this.selectedClient !== '' && this.entireSite === false) {
        this.loadDevicesList = true
        this.deviceEntries = []
        this.header_devices = []
        this.selectedDid = []
        var API_URL = 'http://xx.xx.xx.xx:xxxx'
        fetch('/config.json').then(res => res.json()).then(config => {
          API_URL = config['API_URL']
          var queryData = { 'client': this.selectedClient }
          axios.post(`${API_URL}/api/sl1/devices_pull`, queryData, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
            (payload) => {
              this.loadDevicesList = false
              var retObj = payload.data
              this.deviceEntries = retObj.devices
              this.header_devices = [
                { title: 'Did', key: 'did', align: 'start' },
                { title: 'Device', key: 'device', align: 'start' },
                { title: 'IP', key: 'ip', align: 'start' },
                { title: 'Device Class', key: 'device_class', align: 'start' },
                { title: 'Parent Device', key: 'pareent_device', align: 'start' },
                { title: 'Root Device', key: 'root_device', align: 'start' }
              ]
            })
        })
      }
    }
  }
}
</script>

<style lang="css" scoped>
    .bg {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .select {
      max-width: 60px;
      max-height: 60px;
      font-size: 11px;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:100%;
        height:100%;
        overflow:hidden;
        background-color:#eeeeee; /* for IE7 #fff  #bbaadd before 00bceb */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:1px;
        padding-left: 3px;
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .ttec_left {
      float:left;
      margin-left: 3px;
    }
    .ttec_right {
      float:left;
      margin-left: 6px;
    }
    .v-text-field :deep(input){
        font-size: 0.9em;
        font-weight: 300;
        padding: 0px;
        margin: 0px;
        /* text-transform: capitalize; */
    }
    .v-text-field :deep(label) {
        font-size: 0.6em  !important;
        margin-bottom: 1px !important;
        margin-left: 2px !important;
    }
    .v-text-field :deep(button) {
        font-size: 0.8em;
    }
    .v-input :deep(control) {
      height:10px !important
    }
    .v-data-table :deep(th) {
      font-size: 10px !important;
      padding: 0px;
      margin-left: 0px;
      margin-right: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 10px !important;
      padding: 0px;
      text-align: left;
      margin-left: 0px;
      margin-right: 0px;
      font-weight: 300;
    }
    .v-select__selections input {
      padding: 0px;
      margin: 0px;
    }

    .text-bold-ttec {
      font-weight: bold;
    }
</style>
