<template>
    <div id="datadogEvent" class="bg">
        <div class="text-center">
            <v-chip
                class="ma-1 text-bold"
                color="success"
                outlined
                >
                <v-icon left>
                    mdi-server-plus
                </v-icon>
                Datadog Event Explorer
            </v-chip>
        </div>
        <div class="cui">
            <v-row class="bg2">
                <v-col cols="12">
                    <div class="ttec_contentWrapper text-medium text-bold pa-2 mb-0">
                        Datadog Event Explorer [Past 1 Hour]
                     </div>
                     <v-text-field
                            class="custom-text-field mb-1 ml-0 mr-6 pt-2 mt-1"
                            v-model="search"
                            append-icon="mdi-magnify"
                            density="compact"
                            single-line
                            placeholder="Search by source, title, message, timestamp or tags"
                            hide-details
                        ></v-text-field>
                    <v-card width="100vw">
                    <v-data-table
                        :headers="headerEvents"
                        :items="eventEntries"
                        :items-per-page="50"
                        :search="search"
                        item-key="id"
                        class="elevation-6"
                        density="compact"
                        :footer-props="{
                            'items-per-page-options': [10,20, 50, 100, -1],
                                showFirstLastPage: true,
                                firstIcon: 'mdi-arrow-collapse-left',
                                lastIcon: 'mdi-arrow-collapse-right'
                            }"
                        >
                        <template v-slot:[`item.source`]="{ item }">
                            <div v-if="item.source == 100"> My Apps </div>
                            <div v-else-if="item.source == 36"> Monitor Alert </div>
                            <div v-else-if="item.source == 1"> Datadog </div>
                            <div v-else-if="item.source == 367"> Notifications </div>
                            <div v-else-if="item.source == 205"> Watchdog </div>
                            <div v-else > TBD - {{ item.source }} </div>
                        </template>
                        <template v-slot:[`item.title`]="{ item }">
                            <div v-if="item.title.includes('[Triggered') || item.title.includes('[Re-Triggered')" class="warning">[ERROR] {{ item.title }} </div>
                            <div v-else-if="item.title.includes('[Recovered')" class="healthy">[OK] {{ item.title }} </div>
                            <div v-else class="info"> {{ item.title }} </div>
                        </template>
                        <template v-slot:[`item.message`]="{ item }">
                            <div :title="decode(item.message)">{{ decode(item.message) }}</div>
                        </template>
                        <template v-slot:[`item.tags`]="{ item }">
                            <div v-for="(tag_item, i) in item.tags" :key="i">
                              <v-chip
                                class="ttec_large_text"
                                density="compact"
                                color="#03A9F4"
                                variant="elevated"
                                :text="tag_item.length > 30 ? 
                    tag_item.substring(0, 30 - 3) + '...' : tag_item"
                                :title="tag_item"
                              >
                              </v-chip>
                            </div>
                        </template>
                    </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <br/><br/>
    </div>
</template>

<script>
import axios from 'axios'

export default ({
  name: 'DatadogEvent',
  components: {
  },
  data () {
    return {
      eventEntries: [],
      headerEvents: [],
      sortBy: 'start',
      search: '',
      sortDesc: true
    }
  },

  mounted () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      axios.get(`${API_URL}/api/datadog/event`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          var retObj = payload.data
          this.eventEntries = retObj.event_list
          this.headerEvents = [
            { title: 'Source', key: 'source' },
            { title: 'Title', key: 'title' },
            { title: 'Message', key: 'message' }, 
            { title: 'Timestamp', key: 'timestamp'},
            { title: 'Tags', key: 'tags'  }
          ]
        })
    })
  },
  beforeDestroy () {
  },
  created () {
  },
  methods: {
    decode (input) {
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }
  }
})
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:92%;
        height:auto;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:5px;
        margin-left: 15px;
    }
    .ttec_tenantWrapper
    {
        position:relative;
        /* height:auto; */
        width: 190px;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-left: 3px;
        margin-top: 1px;
    }
    .ttec_content_activeConn_gray {
      padding:1% 1% 2% 2%;
      /* background-color: gray; */
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .ttec_content_activeConn
    {
        padding:1% 1% 2% 2%;
    }
    .text_green
    {
        color:#4CAF50;
    }
    .text_warning
    {
        color:#d9534f;
    }
    .ttec_large_text {
      font-size: 10px !important;
    }
    .healthy {
        background-color: #99cc33;
        font-weight:bold;
    }
    .warning {
        background-color: #ff9966;
        font-weight:bold;
    }
    .info {
        font-weight:bold;
    }
    .critical {
        height: 20px;
        width: 20px;
        background-color: #cc3300;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .cursor {
       cursor: pointer;
    }
    .container {
        display:flex;
        flex-flow:column wrap;
        max-height:290px;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
    }
    .chartRtrAgnet {
      margin-top: 5px;
      margin-left: 20px;
      margin-bottom: 5px;
    }
    .bg2 {
      padding-left: 0px;
      padding-right: 0px;
      background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_orange {
      background-color: #bcc;
      width:75%;
      padding-left: 8px;
      border-radius: 5px;
    }
    .ttec_green {
      background-color: #ddd;
      width:73%;
      margin-left: 10px;
      padding-left:8px;
      border-radius: 5px;
    }
    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: 300;
    }
    .event_active {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #90EE90;
      font-weight:bold;
      background: #90EE90;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .event_expired {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #888888;
      font-weight:bold;
      background: #888888;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_warning {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #FFCC00;
      font-weight:bold;
      background: #FFCC00;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_critical {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #F32013;
      font-weight:bold;
      background: #F32013;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
</style>