<template>
    <v-container class="container">
      <v-app id='adrDatadogCase'>
          <NavBar></NavBar>
      <!-- Content Body - Components <component v-bind:is="component" :key="componentKey" />-->
          <v-content class="pt-0 mt-0 pl-0 ml-0">
          <component v-bind:is="component" :key="componentKey" />
          </v-content>
      <!--Footer FrameWork-->
          <Footer></Footer>
      <!--SnackBar FrameWork-->
          <v-snackbar
              v-model="snackbar"
              :multi-line="multiLine"
              :color="snackbar_color"
              :top="'top'"
              :timeout="snackbar_timeout"
          >
              {{ snackbar_text }}
              <v-btn
              color="red"
              text
              @click="snackbar = false"
              >close
              </v-btn>
          </v-snackbar>
      </v-app>
    </v-container>
  </template>
  
  <script>
  import NavBar from './NavBar'
  import Footer from './Footer'
  import DatadogCase from './DatadogCase'
  import { EventBus } from '@/utils'

  
  export default {
    name: 'AdrDatadogCase',
    metaInfo: {
      title: 'Datadog Cases - ADR Portal - TTEC Digital'
    },
    props: {
      source: String
    },
    components: {
      NavBar,
      Footer,
      DatadogCase
    },
    data: () => ({
      componentKey: 0,
      background: true,
      right: false,
      left: false,
      snackbar: false,
      multiLine: true,
      loginuser: '',
      snackbar_text: 'snackbar_text',
      snackbar_color: 'blue',
      snackbar_timeout: 6000,
      items: [
        { title: 'LogOut' }
      ],
      mini: true,
      computed: {
        bg () {
          return this.background ? '../assets/spaceballs.jpg' : undefined
        }
      },
      component: DatadogCase
    }),
    methods: {
    },
    mounted () {
      if (!this.$store.getters.isAuthenticated) {
        this.$store.dispatch('logout')
        this.$router.replace({ path: '/' })
      }
      EventBus.on('snackbar_msg', (msg) => {
        this.snackbar_text = msg.message
        this.snackbar_color = msg.status
        this.snackbar = true
      })
    }
  }
  
  </script>
  
  <style scoped>
  </style>