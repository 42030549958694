<template>
    <div id="datadogHost" class="bg">
        <div class="text-center">
            <v-chip
                class="ma-1 text-bold"
                color="success"
                outlined
                >
                <v-icon left>
                    mdi-server-plus
                </v-icon>
                Datadog Manage Hosts
            </v-chip>
        </div>
        <div class="cui">
            <v-row class="bg2">
                <v-col cols="11">
                    <div class="ttec_contentWrapper text-medium text-bold pa-2 mb-0">
                        Datadog Manage Hosts
                     </div>
                     <v-text-field
                            class="custom-text-field mb-1 ml-0 mr-6 pt-2 mt-1"
                            v-model="search"
                            append-icon="mdi-magnify"
                            density="compact"
                            single-line
                            placeholder="Search by host name, sources, status or apps"
                            hide-details
                        >
                    </v-text-field>
                    <v-data-table
                        :headers="headerHosts"
                        :items="hostEntries"
                        :items-per-page="50"
                        :search="search"
                        :loading="loadHosts"
                        loading-text="Loading ..."
                        item-key="id"
                        class="elevation-6"
                        density="compact"
                        :footer-props="{
                            'items-per-page-options': [10,20, 50, 100, -1],
                                showFirstLastPage: true,
                                firstIcon: 'mdi-arrow-collapse-left',
                                lastIcon: 'mdi-arrow-collapse-right'
                            }"
                        >
                        <template v-slot:[`item.status`]="{ item }">
                            <div v-if="item.status == 'ACTIVE'" class ="host_active" >{{ item.status }}</div>
                            <div v-else class ="host_expired">{{ item.status }}</div>
                        </template>
                        <template v-slot:[`item.cpu`]="{ item }">
                            <div>{{ item.cpu }}%</div>
                        </template>
                        <template v-slot:[`item.platform`]="{ item }">
                            <div v-if="item.platform == 'windows'" >
                               <v-icon color="blue-darken-2"icon="mdi-view-grid"size="large" title="windows">
                               </v-icon>
                            </div>
                            <div v-else>{{ item.platform }}</div>
                        </template>
                        <template v-slot:[`item.sources`]="{ item }">
                            <div>
                              <v-chip
                                class="ttec_large_text"
                                density="compact"
                                color="#03A9F4"
                                variant="elevated"
                              >
                                {{ item.sources }}
                              </v-chip>
                            </div>
                        </template>
                        <template v-slot:[`item.apps`]="{ item }">
                            <div>
                              <v-chip
                                class="ttec_large_text"
                                density="compact"
                                color="#03A9F4"
                                variant="elevated"
                              >
                                {{ item.apps }}
                              </v-chip>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
        <br/><br/>
    </div>
</template>

<script>
import axios from 'axios'

export default ({
  name: 'DatadogHost',
  components: {
  },
  data () {
    return {
      hostEntries: [],
      headerHosts: [],
      sortBy: 'start',
      search: '',
      loadHosts: false,
      sortDesc: true
    }
  },

  mounted () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      this.loadHosts = true
      axios.get(`${API_URL}/api/datadog/host`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          this.loadHosts = false
          var retObj = payload.data
          this.hostEntries = retObj.host_list
          this.headerHosts = [
            { title: 'Hostname', key: 'host_name' },
            { title: 'Platform', key: 'platform' },
            { title: 'Sources', key: 'sources' },
            { title: 'Status', key: 'status' },
            { title: 'CPU', key: 'cpu',  },
            { title: 'IOWAIT', key: 'iowait',  },
            { title: 'LOAD 15', key: 'load',  },
            { title: 'APPS', key: 'apps',  },
            { title: 'Last Reported', key: 'last_reported_time' }
          ]
        })
    })
  },
  beforeDestroy () {
  },
  created () {
  },
  methods: {
  }
})
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:92%;
        height:auto;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:5px;
        margin-left: 15px;
    }
    .ttec_tenantWrapper
    {
        position:relative;
        /* height:auto; */
        width: 190px;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-left: 3px;
        margin-top: 1px;
    }
    .ttec_content_activeConn_gray {
      padding:1% 1% 2% 2%;
      /* background-color: gray; */
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .ttec_content_activeConn
    {
        padding:1% 1% 2% 2%;
    }
    .text_green
    {
        color:#4CAF50;
    }
    .text_warning
    {
        color:#d9534f;
    }
    .ttec_large_text {
      font-size: 10px !important;
    }
    .healthy {
        height: 20px;
        width: 20px;
        background-color: #99cc33;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .warning {
        height: 20px;
        width: 20px;
        background-color: #ff9966;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .critical {
        height: 20px;
        width: 20px;
        background-color: #cc3300;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .cursor {
       cursor: pointer;
    }
    .container {
        display:flex;
        flex-flow:column wrap;
        max-height:290px;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
    }
    .chartRtrAgnet {
      margin-top: 5px;
      margin-left: 20px;
      margin-bottom: 5px;
    }
    .bg2 {
      padding-left: 0px;
      padding-right: 0px;
      background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_orange {
      background-color: #bcc;
      width:75%;
      padding-left: 8px;
      border-radius: 5px;
    }
    .ttec_green {
      background-color: #ddd;
      width:73%;
      margin-left: 10px;
      padding-left:8px;
      border-radius: 5px;
    }
    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: 300;
    }
    .host_active {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #90EE90;
      font-weight:bold;
      background: #90EE90;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .host_expired {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #888888;
      font-weight:bold;
      background: #888888;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_warning {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #FFCC00;
      font-weight:bold;
      background: #FFCC00;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_critical {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #F32013;
      font-weight:bold;
      background: #F32013;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
</style>