<template>
    <div id="datadogCase" class="bg">
        <div class="text-center">
            <v-chip
                class="ma-1 text-bold"
                color="success"
                outlined
                >
                <v-icon left>
                    mdi-server-plus
                </v-icon>
                Datadog Case Management
            </v-chip>
        </div>
        <div class="cui">
            <v-row class="bg2">
                <v-col cols="12">
                    <div class="ttec_contentWrapper text-medium text-bold pa-2 mb-0">
                        Datadog Case Management
                     </div>
                     <v-text-field
                            class="custom-text-field mb-1 ml-0 mr-6 pt-2 mt-1"
                            v-model="search"
                            append-icon="mdi-magnify"
                            density="compact"
                            single-line
                            placeholder="Search by priority, title, status or description"
                            hide-details
                        ></v-text-field>
                    <v-card width="100vw">
                    <v-data-table
                        :headers="headerCases"
                        :items="caseEntries"
                        :items-per-page="20"
                        :search="search"
                        item-key="key"
                        class="elevation-6"
                        density="compact"
                        :footer-props="{
                            'items-per-page-options': [10,20, 50, 100, -1],
                                showFirstLastPage: true,
                                firstIcon: 'mdi-arrow-collapse-left',
                                lastIcon: 'mdi-arrow-collapse-right'
                            }"
                        >
                        <template v-slot:[`item.priority`]="{ item }">
                            <div v-if="item.priority == 'NOT_DEFINED'"  class="case_p3"> - </div>
                            <div v-else-if="item.priority == 'P1'" class="case_p1" > {{ item.priority }} </div>
                            <div v-else-if="item.priority == 'P2'" class="case_p2" > {{ item.priority }} </div>
                            <div v-else class="case_p3"> {{ item.priority }} </div>
                        </template>
                        <template v-slot:[`item.key`]="{ item }">
                            <div class="time_gray"> {{ item.key }} </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <div v-if="item.status == 'OPEN'" class="case_open">{{ item.status }}</div>
                            <div v-else class="case_close"> {{ item.status }} </div>
                        </template>
                        <template v-slot:[`item.title`]="{ item }">
                            <div class="info"> {{ item.title }} </div>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                            <div :title="decode(item.description)"> {{ decode(item.description).length > 160 ? 
                    decode(item.description).substring(0, 160 - 3) + '...' : decode(item.description) }} </div>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <div class="time_gray"> {{ timeAgo(item.created_at) }} </div>
                        </template>
                        <template v-slot:[`item.modified_at`]="{ item }">
                            <div class="time_gray"> {{ timeAgo(item.modified_at) }} </div>
                        </template>
                    </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <br/><br/>
    </div>
</template>

<script>
import axios from 'axios'

export default ({
  name: 'DatadogCase',
  components: {
  },
  data () {
    return {
      caseEntries: [],
      headerCases: [],
      sortBy: 'start',
      search: '',
      sortDesc: true
    }
  },

  mounted () {
    var API_URL = 'http://xx.xx.xx.xx:xxxx'
    fetch('/config.json').then(res => res.json()).then(config => {
      API_URL = config['API_URL']
      axios.get(`${API_URL}/api/datadog/case`, { headers: { Authorization: 'Bearer ' + this.$store.getters.getJwtToken, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
        (payload) => {
          var retObj = payload.data
          this.caseEntries = retObj.case_list
          this.headerCases = [
            { title: 'Priority', key: 'priority' },
            { title: 'Case Key', key: 'key' },
            { title: 'Title', key: 'title' },
            { title: 'Description', key: 'description' },
            { title: 'Status', key: 'status' }, 
            { title: 'Created', key: 'created_at'},
            { title: 'Updated', key: 'modified_at'}
          ]
        })
    })
  },
  beforeDestroy () {
  },
  created () {
  },
  methods: {
    timeAgo(tsLbl) {
        var utcTimestamp = Date.parse(tsLbl)
        // return utcTimestamp
        const now = new Date();
        const timeDifference = now.getTime() - utcTimestamp;
        //return timeDifference

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return seconds <= 0 ? 'just now' : `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        } else if (minutes < 60) {
            return `${minutes}m ago`;
        } else if (hours < 24) {
            return `${hours}h ago`;
        } else if (days < 30) {
            return `${days}d ago`;
        } else if (months < 12) {
            return `${months}month ago`;
        } else {
            return `${years}year ago`;
        }
    },
    decode (input) {
        const doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }
  }
})
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 100%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:92%;
        height:auto;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:5px;
        margin-left: 15px;
    }
    .ttec_tenantWrapper
    {
        position:relative;
        /* height:auto; */
        width: 190px;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-left: 3px;
        margin-top: 1px;
    }
    .ttec_content_activeConn_gray {
      padding:1% 1% 2% 2%;
      /* background-color: gray; */
    }
    .ttec_content
    {
        padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 2% 2%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .ttec_content_activeConn
    {
        padding:1% 1% 2% 2%;
    }
    .text_green
    {
        color:#4CAF50;
    }
    .text_warning
    {
        color:#d9534f;
    }
    .ttec_large_text {
      font-size: 10px !important;
    }
    .healthy {
        background-color: #99cc33;
        font-weight:bold;
    }
    .warning {
        background-color: #ff9966;
        font-weight:bold;
    }
    .info {
        font-weight:bold;
    }
    .time_gray {
        font-weight:bold;
        color: gray;
    }
    .case_open {
        color: navy;
        background-color: lightskyblue;
        font-weight:bold;
        border-radius: 5px;
        text-align: center;
    }
    .case_close {
        color: green;
        background-color: lightgreen;
        font-weight:bold;
        border-radius: 5px;
        text-align: center;
    }
    .case_p1 {
        color: white;
        background-color: red;
        font-weight:bold;
        border-radius: 5px;
        text-align: center;
    }
    .case_p2 {
        color: rgb(204, 33, 33);
        background-color: rgb(254, 190, 199);
        font-weight:bold;
        border-radius: 5px;
        text-align: center;
    }
    .case_p3 {
        color: gray;
        background-color: lightgray;
        font-weight:bold;
        border-radius: 5px;
        text-align: center;
    }
    .critical {
        height: 20px;
        width: 20px;
        background-color: #cc3300;
        border-radius: 50%;
        display: inline-block;
        margin-left: 15px;
    }
    .cursor {
       cursor: pointer;
    }
    .container {
        display:flex;
        flex-flow:column wrap;
        max-height:290px;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
    }
    .chartRtrAgnet {
      margin-top: 5px;
      margin-left: 20px;
      margin-bottom: 5px;
    }
    .bg2 {
      padding-left: 0px;
      padding-right: 0px;
      background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_orange {
      background-color: #bcc;
      width:75%;
      padding-left: 8px;
      border-radius: 5px;
    }
    .ttec_green {
      background-color: #ddd;
      width:73%;
      margin-left: 10px;
      padding-left:8px;
      border-radius: 5px;
    }
    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: 300;
    }
    .event_active {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #90EE90;
      font-weight:bold;
      background: #90EE90;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .event_expired {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #888888;
      font-weight:bold;
      background: #888888;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_warning {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #FFCC00;
      font-weight:bold;
      background: #FFCC00;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
    .svc_critical {
      cursor: pointer;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #F32013;
      font-weight:bold;
      background: #F32013;
      box-shadow: 4px 3px 8px 1px #969696;
      -webkit-box-shadow: 4px 3px 8px 1px #969696;
    }
</style>