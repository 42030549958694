<template>
    <div id="svcStatus" class="bg">
        <div class="text-center">
          <div class="cui">
              <br/><br/>
              <v-chip
                  class="ma-1 text-bold text-medium"
                  outlined
                  >
                  Tenant Service Status
              </v-chip>
            </div>
        </div>
        <div class="cui">
            <v-row dense>
                <v-col density="compact" cols="6">
                    <div class="ttec_contentWrapper text-medium text-bold pa-2 mb-0">
                        Tenants Status Overview <h9 class="ttec_xsmall ml-6">last updated on  {{ last_update_lbl }}</h9>
                    </div>
                    <div class="container ma-0 pr-0">
                        <div class="ttec_tenantWrapper" v-for="tenant in tenants" :key="tenant.name">
                            <div :class="selectedName == tenant.name? 'ttec_content_selected' : 'ttec_tenant_content'">
                              <v-chip density="compact" class="fullwidth text-xsmall x-small text-bold cursor pa-1" @click="showStatus(tenant.name, tenant.did)" :title="'[did: ' + tenant.did + '] last updated on ' + tenant.last_update">
                                <span :class="tenant.status"></span> <span>{{tenant.name.substring(0,35)}} </span> 
                            </v-chip>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col  density="compact" cols="6">
                   <div v-if="this.selectedName != ''" class="ttec_contentWrapper">
                        <div class="ttec_content mb-0">
                            <v-chip class="text-large text-bold mb-0"> {{ this.selectedName }} </v-chip>
                        </div>
                        <div class="ttec_content mt-0">
                            <v-chip class="text-medium text-bold mb-4">Tenant Service Status</v-chip><div class="ml-5" v-if="this.loadTenantData">Loading ......</div>
                            <ul id="svcgrp2">
                                <li v-for="item in svcStatusGrpTenant" :key="item.name">
                                <v-row no-gutters>
                                  <v-col>
                                      <div class="text-medium mb-3"> {{ item.name }}</div>
                                  </v-col>
                                  <v-col>
                                      <div v-if="item.status == 'Operational'" class="text-medium text-bold text_green mb-3"> {{ item.status}} </div>
                                      <div v-else>
                                          <v-tooltip bottom color="grey darken-3" max-width="990px" transition="fade-transition" open-delay="500"><template v-slot:activator="{ props }">
                                              <div class="text-medium text-bold text_warning mb-3" v-bind="props" v-on="on">{{ item.status}}</div>
                                              </template><span class="text-medium" v-html= item.events :style="{'font-size': '13px'}"></span>
                                          </v-tooltip>
                                      </div>
                                  </v-col>
                                </v-row>
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <div v-if="this.selectedName != ''" class="ttec_contentWrapper ml-8">
                    <v-chip class="text-large text-bold ml-7 mt-1 mb-0"> Tenant Alarms / Events </v-chip>
                    <div class="ttec_content mt-0 mb-0">
                          <v-data-table
                            :headers="header_tenant_alarms"
                            :items="tenant_alarms"
                            :loading="loadTenantData"
                            :footer-props="{
                              'items-per-page-options': [10, 20, 30, 40, 50]
                            }"
                            :items-per-page="20"
                            loading-text="Loading ..."
                            density="compact"
                            item-key="message"
                          >
                          <template #bottom></template>
                        </v-data-table>
                    </div>
                </div>
            </v-row>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'

export default ({
  name: 'ServiceStatusDesktop',
  components: {
  },
  data () {
    return {
      selectedName: '',
      last_update_lbl: '',
      selectedDid: 0,
      svcStatusGrpTenant: [],
      tenant_alarms: [],
      header_tenant_alarms: [],
      tenants: [],
      loadTenantData: false
    }
  },
  mounted () {
    this.getTenantStatus()
  },
  beforeDestroy () {
    this.svcStatusGrpTenant = null
  },
  created () {
  },
  methods: {
    getTenantStatus() {
      var API_URL = 'http://xx.xx.xx.xx:xxxx'
      fetch('/config.json').then(res => res.json()).then(config => {
        API_URL = config['API_URL']
        axios.get(`${API_URL}/api/sl1/wxcce_tenant_h1d5hvyp8p0wl1rv2ptbh1lyyopb_desktop`, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
          (payload) => {
            var retObj = payload.data
            this.tenants = retObj.tenant_list
            this.last_update_lbl = retObj.last_update
          })
      })
    },
   
    showStatus (name, did) {
      this.selectedName = name
      this.selectedDid =  did
      this.svcStatusGrpTenant = []
      this.tenant_alarms = []
      var API_URL = 'http://xx.xx.xx.xx:xxxx'
      this.loadTenantData = true
      fetch('/config.json').then(res => res.json()).then(config => {
        API_URL = config['API_URL']
        axios.get(`${API_URL}/api/sl1/wxcce_tenant_status_h1d5hvyp8p0wl1rv2ptbh1lyyopb_desktop?did=` + did, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } }).then(
          (payload) => {
            this.loadTenantData = false
            var retObj = payload.data
            this.svcStatusGrpTenant = retObj.tenant_status
            this.tenant_alarms = retObj.tenant_alarms
            this.header_tenant_alarms = [
              {  key: 'device', title: 'Device' },
              {  key: 'message' ,title: 'Message' },
              {  key: 'severity', title: 'Severity' },
              {  key: 'ticket',title: 'Ticket' },
              {  key: 'ticket_status', title: 'Ticket Status' },
              {  key: 'time_modify', title: 'Time Notified' }
            ]
          })
      })
      
    }
  }
})
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 120%;
        /* position: absolute; */
        top: 0;
        left: 0;
        margin: 0px 0px;
        background: url( '../assets/img/pattern.jpg') repeat;
    }
    .ttec_contentWrapper
    {
        position:relative;
        width:96%;
        height:auto;
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-bottom:5px;
        margin-left: 15px;
    }
    .ttec_tenantWrapper
    {
        position:relative;
        /* height:auto; */
        height: auto;
        /*width: 25%; */
        overflow:hidden;
        background-color:#ffffff; /* for IE7 #fff before */
        box-shadow: 10px 10px 6px #888888;
        border-radius: 6px;
        margin-left: 3px;
        margin-top: 1px;
    }
    .ttec_content_activeConn_gray {
      padding:1% 1% 2% 2%;
      /* background-color: gray; */
    }
    .ttec_content
    {
      padding:1% 1% 2% 2%;
    }
    .ttec_tenant_content {
      padding:1% 1% 2% 2%;
    }
    .ttec_content_selected
    {
        padding:1% 1% 1% 1%;
        border-style: solid;
        border-width: 2px;
        border-color: coral;
    }
    .ttec_content_activeConn
    {
        padding:1% 1% 2% 2%;
    }
    .text_green
    {
        color:#4CAF50;
    }
    .text_warning
    {
        color:#d9534f;
    }
    .Healthy {
        height:13px;
        width: 13px;
        background-color: #99cc33;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
    }
    .Notice {
      height: 13px;
        width: 13px;
        background-color: rgb(0, 191, 255);
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
    }
    .Minor {
      height: 13px;
        width: 13px;
        background-color: yellow;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
    }
    .Major {
        height: 13px;
        width: 13px;
        background-color: #ff9966;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
    }
    .Critical {
        height: 13px;
        width: 13px;
        background-color: #cc3300;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
    }
    .cursor {
       cursor: pointer;
    }
    .container {
        display:flex;
        flex-flow:column wrap;
        max-height:520px;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
    }
    .chartRtrAgnet {
      margin-top: 5px;
      margin-left: 20px;
      margin-bottom: 5px;
    }
    .bg2 {
      background-color:#bbb;
    }
    .ttec_orange {
      background-color: #bcc;
      width:75%;
      padding-left: 8px;
      border-radius: 5px;
    }
    .ttec_green {
      background-color: #ddd;
      width:73%;
      margin-left: 10px;
      padding-left:8px;
      border-radius: 5px;
    }

    .scroller {
      max-width: 600px;
    }

    .scroller__inner {
      padding-block: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .scroller[data-animated="true"] {
      overflow: hidden;
      -webkit-mask: linear-gradient(
        90deg,
        transparent,
        white 20%,
        white 80%,
        transparent
      );
      mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    }

    .scroller[data-animated="true"] .scroller__inner {
      width: max-content;
      flex-wrap: nowrap;
      animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
    }

    .scroller[data-direction="right"] {
      --_animation-direction: reverse;
    }

    .scroller[data-direction="left"] {
      --_animation-direction: forwards;
    }

    .scroller[data-speed="fast"] {
      --_animation-duration: 20s;
    }

    .scroller[data-speed="slow"] {
      --_animation-duration: 60s;
    }

    @keyframes scroll {
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }
    .tag-list {
      margin: 0;
      padding-inline: 0;
      list-style: none;
      padding: 0%;
    }

    .tag-list li {
      font-size: 14px;
      font-weight: bold;
      color: #cc3300;
      padding: 1rem;
      background: var(--clr-primary-400);
      border-radius: 0.5rem;
      box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
    }

    /* for testing purposed to ensure the animation lined up correctly */
    .test {
      background: red !important;
    }

    .ttec_xsmall {
      font-size: 10px;
    }

    .v-data-table :deep(th) {
      font-size: 11px !important;
      padding: 0px;
      font-weight: bold !important;
      background-color: #B0BEC5;
    }
    .v-data-table :deep(td) {
      font-size: 11px !important;
      text-align: left;
      padding: 0px;
      font-weight: 300;
    }

    .fullwidth {
      /*width: 100%;*/
      display: flex;
      justify-content: space-between;
    }

    .chartRtrAgent {
      margin-top: 5px;
      margin-left: 20px;
      margin-bottom: 5px;
    }
</style>
